import React from "react";
import {useMutation} from "@apollo/react-hooks";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {LoginForm, LoginFormOnSubmitArgs, LoginFormProps} from "../components/LoginForm";
import {gql} from "apollo-boost";

const SEND_MESSAGE = gql`
    mutation SendMessage($username: String!, $password: String!, $message: String!) {
        run(credentials: {username: $username, password: $password}, message: $message) {
            success
        }
    }
`;

export const Login: React.FC = () => {
  const [sendMessage, {data}] = useMutation(SEND_MESSAGE);
  const onSubmit = ({username, password, message}: LoginFormOnSubmitArgs) => sendMessage({
    variables: {
      username,
      password,
      message
    }
  });
  console.log('data');
  console.log(data);
  return (
    <div className={'center'}>
      <Container>
        <Row className={'mt-5'}>
          <Col>
            <p className='text-mt-left'>Send a message to one of your followers chosen randomly. Currently, this only
              works if you normally log into
              Instagram with a username and password rather than logging in with Facebook</p>
          </Col>
        </Row>
        <Row className={'mt-5'}>
          <Col>
            <LoginForm onSubmit={onSubmit}/>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
