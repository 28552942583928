import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";

export interface LoginFormOnSubmitArgs {
  username: string, password: string, message: string
}
export interface LoginFormProps {
  onSubmit: ({username, password, message}: LoginFormOnSubmitArgs) => void;
}

export function LoginForm({onSubmit}: LoginFormProps) {
  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    const username = form.elements.formBasicUsername.value;
    const password = form.elements.formBasicPassword.value;
    const message = form.elements.formBasicMessage.value;
    onSubmit({username, password, message});
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formBasicUsername">
        <Form.Label>Username</Form.Label>
        <Form.Control required type="text" placeholder="Your Instagram username"/>
        <Form.Control.Feedback type={'valid'}>Invalid</Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control required type="password" placeholder="Your Instagram password" />
      </Form.Group>
      <Form.Group controlId="formBasicMessage">
        <Form.Label>Message</Form.Label>
        <FormControl required as="textarea"/>
      </Form.Group>
      <Button variant="primary" type="submit">
        Send
      </Button>
    </Form>
  );
}

