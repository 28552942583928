import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ApolloClient, {gql} from 'apollo-boost';
import {ApolloProvider, useMutation} from '@apollo/react-hooks';
import {Login} from "./containers/Login";


const uri = process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : 'https://he1bif8f7k.execute-api.ap-southeast-2.amazonaws.com/prod';


const client = new ApolloClient({uri});
const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Login/>
    </ApolloProvider>
  );
};

export default App;
